import * as ReactDOMServer from 'react-dom/server';
import L from 'leaflet'
import { Marker, Popup, Tooltip } from 'react-leaflet';
import { Typography } from '@mui/material';
import Asset from '../../models/Asset';

import './AssetMarkerTooltip.css'

function buildSvgIcon(assetColour: string, heading?: number) {

    let rotation = heading || 0;
    let centerIcon = (<polygon points="60 30 40 80 60 70 80 80" fill="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="10px" />);

    return (
        <svg width="40" height="40">
            <g transform={"rotate(" + rotation + " 20 20) scale(0.33)"}>
                <circle cx="60" cy="60" r="50" stroke={assetColour} strokeWidth="4" fill="white" />
                <circle cx="60" cy="60" r="40" stroke="none" fill="#21994D" />
                {centerIcon}
            </g>
        </svg>)
}

function AssetMarker(props: Asset) {
    const assetColour = props.colour && props.colour.toLowerCase();
    const svg = buildSvgIcon(assetColour, props.location.direction)
    const customMarkerIcon = L.divIcon({
        html: ReactDOMServer.renderToString(svg),
        iconSize: [40, 40],
        iconAnchor: [20, 20],
        popupAnchor: [0, -20],
        className: "none"
    });

    return (
        <Marker position={{ lat: props.location.coordinates.latitude, lng: props.location.coordinates.longitude }} icon={customMarkerIcon}>
                <Tooltip direction="top" offset={[0, -20]} opacity={1} permanent>
                    <Typography
                        component="span"
                        color="text.primary"
                        sx={{ fontWeight: 'bold'}}>
                        {props.displayName}
                    </Typography>
                </Tooltip>
            <Popup>
                {props.location.address}
            </Popup>
        </Marker>
    );
}

export default AssetMarker;
