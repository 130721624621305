class Exception {
    errorDetail?: string;
    errorMessage: string;
    errorType: string;
    httpStatusCode: number;
    uuid: string;
    period?: string;
    startTime?: string;
    endTime?: string

    constructor(errorMessage: string, errorType: string, httpStatusCode: number,
                uuid: string, errorDetail?: string, period?: string, startTime?: string, endTime?: string) {
        this.errorMessage = errorMessage;
        this.errorType = errorType;
        this.httpStatusCode = httpStatusCode;
        this.uuid = uuid;
        this.errorDetail = errorDetail;
        this.period = period;
        this.startTime = startTime;
        this.endTime = endTime;
    }
}

export default Exception;