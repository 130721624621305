import LiveShareApp from "./components/LiveShareApp";

function App() {
  return (
    <>
      <LiveShareApp />
    </>
  );
}

export default App;
